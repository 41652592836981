.select2-result .select2-result-repository__avatar{
    float: left;
    margin-right: 10px;
    font-size: 20px;
}
.select2-result  .select2-result-repository__title{
    font-weight: bold;
    font-size: 13px;
}
.select2-result  .select2-result-repository__description{
    font-size: 12px;
}
.select2-container--default .select2-selection--single .select2-selection__clear{
    margin-right: 28px !important;
    padding-right: 0 !important;
    margin-top: 5px !important;
    font-size: 16px;
    z-index: 2;
}
.select2-container .select2-search--inline .select2-search__field{
    margin-top: 10px;
}
.select2-result-repository__wrap__left{
    float: left
}
.select2-result-repository__wrap__right{
    float: right;
    width: 100px;
}
.select2-result-repository__note{
    width: 100%;
    text-align: right;
    font-size: 12px;
}
.select2-result-repository__price{
    font-size: 12px;
    color: #F17171;
    margin: 0 30px;
}

/*****************/

.select2-container {
    display: block;
}
select + .select2-container {
    z-index: 1;
}

.select2-container *:focus {
    outline: 0;
}
.select2-container .select2-selection {
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .select2-container .select2-selection {
        transition: none;
    }
}
.select2-container.select2-container--focus .select2-selection, .select2-container.select2-container--open .select2-selection {
    border-color: #86b7fe;
}
.select2-container .select2-dropdown .select2-search .select2-search__field:focus {
    border-color: #86b7fe;
}
.select2-container .select2-search--inline .select2-search__field{
    margin-top: 0;
}
.select2-container .select2-selection--multiple .select2-search .select2-search__field {
    width: 100%;
    font-family: inherit;
    background-color: transparent;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
    background-color: rgba(91,113,185,.1);
    border: 1px solid rgba(91,113,185,.1);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    border-right: 0;
}
.select2-container .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder{
    color: #212529;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 36px;
}
.select2-container .select2-selection--single .select2-selection__rendered{
    padding: 6px;
}
.select2-container .select2-selection--multiple .select2-search .select2-search__field {
    font-size: 14px;
    height: 20px;
    margin-top: 8px;
}
.select2-dropdown{
    border: 1px solid #86b7fe;
}

/*****************/
.select2_color .select2-container .select2-selection--single .select2-selection__rendered{
    color: #fff !important;
}
.select2_color .select2-container--default .select2-selection--single .select2-selection__clear{
    color: #fff !important;
}
.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow b{
    border-color: #fff transparent transparent transparent;
}
.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow{
    background: hsla(0,0%,100%,.1);
    right: 0;
    top: 0;
    bottom: 0;
    height: 38px;
    border-radius: 0 3px 3px 0;
}
