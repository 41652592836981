#breadcrumbs {
    background-color: #e9ecef;
}

.alert.header-message {
    border-radius: 0 !important;
}

[data-layout="horizontal"] {
    @media (min-width: 1024.1px) {
        .layout-width,
        .container-fluid {
            max-width: 100%;
            margin: 0 auto;
        }
        .topnav-hamburger {
            visibility: hidden;
        }
    }
}

.remove-date,
.reset-input {
    position: relative;
}

.remove-date:hover .reset,
.reset-input:hover .reset {
    display: block !important;
}

.remove-date .range-del,
.remove-date .reset,
.reset-input .range-del,
.reset-input .reset {
    position: absolute;
    bottom: 10px;
    right: 5px;
    top: auto;
    cursor: pointer;
    z-index: 10;
    color: #999;
    display: none;
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active{
    color: #000;
}

.form-group {
    margin-bottom: 1rem;
}

.table-diff-color tr:nth-child(even) {
    background-color: #f2f2f2; /* Màu cho các dòng chẵn */
}
