table.dataTable tr.dtrg-group th{
    background-color: #e9ecef !important;
}
td {

}

table.table-increase tbody{
    counter-reset: rowNumber;
}

table.table-increase tbody tr > td.row_number
{
    counter-increment: rowNumber;
    vertical-align: middle;
    line-height: normal;
}

table.table-increase tbody tr td.row_number::before
{
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
}

.table tr .hidden-menu {
    opacity: 0;
}

.table tr:hover .hidden-menu {
    opacity: 1
}

.table-diff-color tr:nth-child(even) {
    background-color: #f2f2f2; /* Màu cho các dòng chẵn */
}
