@charset "UTF-8";
#breadcrumbs {
  background-color: #e9ecef;
}

.alert.header-message {
  border-radius: 0 !important;
}

@media (min-width: 1024.1px) {
  [data-layout=horizontal] .layout-width,
[data-layout=horizontal] .container-fluid {
    max-width: 100%;
    margin: 0 auto;
  }
  [data-layout=horizontal] .topnav-hamburger {
    visibility: hidden;
  }
}

.remove-date,
.reset-input {
  position: relative;
}

.remove-date:hover .reset,
.reset-input:hover .reset {
  display: block !important;
}

.remove-date .range-del,
.remove-date .reset,
.reset-input .range-del,
.reset-input .reset {
  position: absolute;
  bottom: 10px;
  right: 5px;
  top: auto;
  cursor: pointer;
  z-index: 10;
  color: #999;
  display: none;
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active {
  color: #000;
}

.form-group {
  margin-bottom: 1rem;
}

.table-diff-color tr:nth-child(even) {
  background-color: #f2f2f2; /* Màu cho các dòng chẵn */
}

table.dataTable tr.dtrg-group th {
  background-color: #e9ecef !important;
}

table.table-increase tbody {
  counter-reset: rowNumber;
}

table.table-increase tbody tr > td.row_number {
  counter-increment: rowNumber;
  vertical-align: middle;
  line-height: normal;
}

table.table-increase tbody tr td.row_number::before {
  content: counter(rowNumber);
  min-width: 1em;
  margin-right: 0.5em;
}

.table tr .hidden-menu {
  opacity: 0;
}

.table tr:hover .hidden-menu {
  opacity: 1;
}

.table-diff-color tr:nth-child(even) {
  background-color: #f2f2f2; /* Màu cho các dòng chẵn */
}

#login {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
}

.login-wrapper {
  width: 100%;
  position: relative;
}

.login-wrapper .login-img-block {
  width: 60%;
  float: left;
}

.login-wrapper .login-content-block {
  float: left;
}

.login-wrapper .login-img-block img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.login-wrapper .clear-both {
  clear: both;
}

.login-wrapper .login-content-block {
  width: 40%;
}

.login-wrapper .login-block {
  padding: 80px 100px 24px;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  align-items: center;
}

.login-submit-btn {
  border: 1px solid #e6e5e5;
  border-radius: 4px;
}

.login-wrapper .login-block .layout-login > .login-submit-btn:hover {
  border-color: #fbab19 !important;
}

.df-border {
  border: 1px solid #ccc;
}

.df-border:hover {
  border-color: #fbab19 !important;
}

.layout-login .logo-block .logo {
  text-align: center;
  margin: 20px 0 0 0;
}

.layout-login .logo-block .logo img {
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.layout-login .logo-block .logo-header {
  font-size: 22px;
  line-height: 40px;
}

.layout-login .form-group-agents label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 5px;
  letter-spacing: -1px;
}

.layout-login .form-group-agents .has-feedback .form-control-agents {
  border-radius: 3px;
  padding-right: 20.5px;
  padding-left: 12.5px;
  height: 40px;
  font-size: 15px;
}

.form-group-agents .has-feedback {
  margin-bottom: 10px !important;
}

.form-group-agents .has-feedback i.fa {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 15px;
  color: #e74;
}

.error_border {
  border: 1.2px solid #e74;
}

.error_form {
  margin-top: 3px;
  color: #e74;
}

.form-group-agents .has-feedback.success i.fa-check-circle {
  color: #2ecc71;
  visibility: visible;
}

.form-group-agents .has-feedback.error i.fa-exclamation-circle {
  color: #e74;
  visibility: visible;
}

.form-group-agents .has-feedback.error p {
  color: #e74c3c;
  visibility: visible;
}

.form-group-agents .has-feedback .form-control-feedback {
  left: 0 !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.box-submit-agents {
  width: 100%;
  text-align: center;
}

.layout-login .login-btn-submit {
  color: #fff;
  font-weight: 500;
  height: 45px;
}

.layout-login .login-btn-submit:hover {
  color: #fff;
  font-weight: 500;
}

.layout-login .forgot-pw a {
  color: #fbab19;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .login-wrapper .login-content-block .login-block {
    padding: 70px 60px 24px;
  }
}
@media (max-width: 767px) {
  .login-wrapper {
    width: 100%;
    position: relative;
  }
  .login-wrapper .login-img-block {
    display: none;
  }
  .login-wrapper .login-content-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .login-wrapper .login-content-block .login-block {
    padding: 20px;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .login-wrapper .login-img-block {
    width: 40%;
  }
  .login-wrapper .login-content-block {
    width: 60%;
  }
}
.btn-login-cx {
  color: #fff;
  background-color: #c81f26;
  border-color: #c81f26;
}

.select2-result .select2-result-repository__avatar {
  float: left;
  margin-right: 10px;
  font-size: 20px;
}

.select2-result .select2-result-repository__title {
  font-weight: bold;
  font-size: 13px;
}

.select2-result .select2-result-repository__description {
  font-size: 12px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  margin-right: 28px !important;
  padding-right: 0 !important;
  margin-top: 5px !important;
  font-size: 16px;
  z-index: 2;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 10px;
}

.select2-result-repository__wrap__left {
  float: left;
}

.select2-result-repository__wrap__right {
  float: right;
  width: 100px;
}

.select2-result-repository__note {
  width: 100%;
  text-align: right;
  font-size: 12px;
}

.select2-result-repository__price {
  font-size: 12px;
  color: #F17171;
  margin: 0 30px;
}

/*****************/
.select2-container {
  display: block;
}

select + .select2-container {
  z-index: 1;
}

.select2-container *:focus {
  outline: 0;
}

.select2-container .select2-selection {
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container .select2-selection {
    transition: none;
  }
}
.select2-container.select2-container--focus .select2-selection, .select2-container.select2-container--open .select2-selection {
  border-color: #86b7fe;
}

.select2-container .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #86b7fe;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container .select2-selection--multiple .select2-search .select2-search__field {
  width: 100%;
  font-family: inherit;
  background-color: transparent;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: rgba(91, 113, 185, 0.1);
  border: 1px solid rgba(91, 113, 185, 0.1);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-right: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #212529;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 6px;
}

.select2-container .select2-selection--multiple .select2-search .select2-search__field {
  font-size: 14px;
  height: 20px;
  margin-top: 8px;
}

.select2-dropdown {
  border: 1px solid #86b7fe;
}

/*****************/
.select2_color .select2-container .select2-selection--single .select2-selection__rendered {
  color: #fff !important;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__clear {
  color: #fff !important;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow {
  background: hsla(0deg, 0%, 100%, 0.1);
  right: 0;
  top: 0;
  bottom: 0;
  height: 38px;
  border-radius: 0 3px 3px 0;
}