#login {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100vh;
}

.login-wrapper {
    width: 100%;
    position: relative;
}
.login-wrapper .login-img-block {
    width: 60%;
    float: left;
}
.login-wrapper .login-content-block {
    float: left;
}
.login-wrapper .login-img-block img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
}
.login-wrapper .clear-both {
    clear: both;
}
.login-wrapper .login-content-block {
    width: 40%;
}
.login-wrapper .login-block {
    padding: 80px 100px 24px;
    width: 100%;
    min-height: 100vh;
    background: #fff;
    align-items: center;
}
.login-submit-btn {
    border: 1px solid #e6e5e5;
    border-radius: 4px;
}
.login-wrapper .login-block .layout-login > .login-submit-btn:hover {
    border-color: #fbab19!important;
}
.df-border {
    border: 1px solid #ccc;
}
.df-border:hover {
    border-color: #fbab19!important;
}
.layout-login .logo-block {}

.layout-login .logo-block .logo {
    text-align: center;
    margin: 20px 0 0 0;
}
.layout-login .logo-block .logo img {
    height: 50px;
    object-fit: cover;
    object-position: center;
}

.layout-login .logo-block .logo-header{
    font-size: 22px;
    line-height: 40px;
}
.layout-login .form-group-agents label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 5px;
    letter-spacing: -1px;
}
.layout-login .form-group-agents input {}

.layout-login .form-group-agents .has-feedback .form-control-agents {
    border-radius: 3px;
    padding-right: 20.5px;
    padding-left: 12.5px;
    height: 40px;
    font-size: 15px;
}
.form-group-agents .has-feedback {
    margin-bottom: 10px !important;
}
.form-group-agents .has-feedback i.fa {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 15px;
    color: #e74;
}
.error_border {
    border: 1.2px solid #e74;
}
.error_form {
    margin-top: 3px;
    color: #e74;
}
.form-group-agents .has-feedback p {

}
.form-group-agents .has-feedback.success input {
    //border: 1.2px solid #2ecc71;
}
.form-group-agents .has-feedback.success i.fa-check-circle {
    color: #2ecc71;
    visibility: visible;
}
.form-group-agents .has-feedback.error input{
    //border: 1.2px solid #e74;
    //visibility: visible;
}
.form-group-agents .has-feedback.error i.fa-exclamation-circle {
    color: #e74;
    visibility: visible;
}
.form-group-agents .has-feedback.error p{
    color: #e74c3c;
    visibility: visible;
}
.form-group-agents .has-feedback .form-control-feedback {
    left: 0 !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}
.box-submit-agents {
    width: 100%;
    text-align: center;
}

.layout-login .login-btn-submit {
    color: #fff;
    font-weight: 500;
    height: 45px;
}
.layout-login .login-btn-submit:hover {
    color: #fff;
    font-weight: 500;
}
.layout-login .forgot-pw {}
.layout-login .forgot-pw a {
    color: #fbab19;
}
@media (max-width: 1200px) and (min-width: 768px){
    .login-wrapper .login-content-block .login-block {
        padding: 70px 60px 24px;
    }
}
@media (max-width: 767px){
    .login-wrapper {
        width: 100%;
        position: relative;
    }
    .login-wrapper .login-img-block {
        display: none;
    }
    .login-wrapper .login-content-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .login-wrapper .login-content-block .login-block {
        padding: 20px;
    }
}
@media (max-width: 1200px) and (min-width: 768px){
    .login-wrapper .login-img-block {
        width: 40%;
    }
    .login-wrapper .login-content-block {
        width: 60%;
    }
}

.btn-login-cx {
    color: #fff;
    background-color: #c81f26;
    border-color: #c81f26;
}
